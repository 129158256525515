import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@mui/material'
import _get from 'lodash/get'
import AppTypography from 'src/components/app-typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { InlineWidget } from 'react-calendly'
import { styled } from '@mui/system'

const StyledAccordianSummary = styled(AccordionSummary)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'white',
  borderBottom: '1px solid lightgrey',
  // borderRadius: '20px',
  height: '40px',

  '&.Mui-expanded': {
    height: '40px',
    minHeight: '40px',
  },

  '&:not(.Mui-expanded)': {
    height: '40px',
    minHeight: '40px',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
  },
}))

const StyledAccordian = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'white',
}))

const StyledAccordianDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingTop: '0px',
}))

function AgencyDetailCard({ agency, prefrencesToShow }) {
  const getTimeIn12HourFormat = time => {
    const result = time ? moment(time, 'HH:mm:ss').format('hh:mm A') : ''
    return result
  }

  return (
    <>
      <Box sx={{ height: '100%', marginTop: '5px' }}>
        <StyledAccordian defaultExpanded>
          <StyledAccordianSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <AppTypography
              style={{ margin: '0px' }}
              variant="subtitle1"
              gutterBottom
            >
              Agency Details
            </AppTypography>
          </StyledAccordianSummary>
          <AccordionDetails>
            {agency && (
              <Box
                sx={{
                  height: '100%',
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box mb={1} />

                  <Grid container spacing={2}>
                    {agency?.agency_name && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Agency Name:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.agency_name}
                        </AppTypography>
                      </Grid>
                    )}
                    {_get(agency, 'agency_type.agency_type') && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Agency Type:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {_get(agency, 'agency_type.agency_type')?.join(', ')}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.contact_name && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Contact Person:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.contact_name}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.address && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Agency Address:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.address} {agency?.city} {agency?.state}{' '}
                          {agency?.zip_postal_code}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.agency_website && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Agency Website:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.agency_website}
                        </AppTypography>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={6}>
                      <AppTypography variant="subtitle2">
                        Office hours:
                      </AppTypography>
                      <AppTypography variant="body2" color="text.secondary">
                        {agency?.office_hours_from === '12:00 AM' &&
                          agency?.office_hours_to === '11:59 PM'
                          ? 'Open 24 Hours'
                          : `${agency?.office_hours_from} - ${agency?.office_hours_to}`}
                      </AppTypography>
                    </Grid>
                    {agency?.office_hours_timezone && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Office Timezone:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.office_hours_timezone}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.payment_option?.payment_option && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Payment Options:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.payment_option?.payment_option.join(', ')}
                        </AppTypography>
                      </Grid>
                    )}

                    {agency?.coverage_area_cities && (
                      <Grid item xs={12}>
                        <AppTypography variant="subtitle2">
                          Coverage area cities:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.coverage_area_cities}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.coverage_area_zipcodes && (
                      <Grid item xs={12}>
                        <AppTypography variant="subtitle2">
                          Coverage zip codes:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.coverage_area_zipcodes}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.service_provided?.service_provided && (
                      <Grid item xs={12}>
                        <AppTypography variant="subtitle2">
                          Services Provided:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.service_provided?.service_provided.join(
                            ', '
                          )}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.description && (
                      <Grid item xs={12}>
                        <AppTypography variant="subtitle2">
                          Agency Description:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.description} Hours
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.min_hours_per_visit && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Min. Hrs of Care Req/Visit:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.min_hours_per_visit} Hours
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.min_hours_per_day && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Min. Hrs of Care Req/Day:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.min_hours_per_day} Hours
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.min_hours_per_week && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Min. Hrs of Care Req/Week:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.min_hours_per_week} Hours
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.pricing_hour_detail && (
                      <Grid item xs={12} sm={6} md={6}>
                        <AppTypography variant="subtitle2">
                          Pricing and Hours Details and Term:
                        </AppTypography>
                        <AppTypography variant="body2" color="text.secondary">
                          {agency?.pricing_hour_detail}
                        </AppTypography>
                      </Grid>
                    )}
                    {agency?.rates?.hourly_rate?.from &&
                      agency?.rates?.hourly_rate?.to && (
                        <Grid item xs={12} sm={6} md={6}>
                          <AppTypography variant="subtitle2">
                            Hourly Rate Range:
                          </AppTypography>
                          <AppTypography variant="body2" color="text.secondary">
                            ${agency?.rates?.hourly_rate?.from} - $
                            {agency?.rates?.hourly_rate?.to}
                          </AppTypography>
                        </Grid>
                      )}
                    {agency?.rates?.livein_rate?.from &&
                      agency?.rates?.livein_rate?.to && (
                        <Grid item xs={12} sm={6} md={6}>
                          <AppTypography variant="subtitle2">
                            Live-In Rate Range:
                          </AppTypography>
                          <AppTypography variant="body2" color="text.secondary">
                            ${agency?.rates?.livein_rate?.from} - $
                            {agency?.rates?.livein_rate?.to}
                          </AppTypography>
                        </Grid>
                      )}
                  </Grid>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </StyledAccordian>
        <Box mb={2}></Box>

        {prefrencesToShow?.map(key => {
          if (
            (key == 'client-lead' &&
              (agency?.agency_preferences?.clientBookingPreferences?.length ||
                agency?.calendlyClientAppointmentUrl)) ||
            (key == 'caregiver-job-lead' &&
              (agency?.agency_preferences
                ?.employeeInterviewBookingPreferences?.length ||
                agency?.calendlyCaregiverInterviewUrl))
          ) {
            return (
              <>
                <StyledAccordian defaultExpanded>
                  <StyledAccordianSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <AppTypography
                      style={{ margin: '0px' }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      {key == 'client-lead'
                        ? 'Client Booking Prefrences'
                        : 'Employee Interview Booking Preferences'}
                    </AppTypography>
                  </StyledAccordianSummary>
                  <StyledAccordianDetails>
                    <Box
                      sx={{
                        height: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box display="flex" flexDirection="column" flex={1}>
                        {key == 'client-lead' &&
                          agency?.agency_preferences
                            ?.clientBookingPreferences &&
                          typeof agency?.agency_preferences
                            ?.clientBookingPreferences == 'object' &&
                          agency?.agency_preferences?.clientBookingPreferences
                            ?.length && (
                            <Grid item xs={12} sm={12} md={12}>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableBody>
                                    {agency?.agency_preferences?.clientBookingPreferences
                                      ?.filter(row => row?.timeframes?.length)
                                      ?.map(row => (
                                        <TableRow
                                          key={row?.dayOfWeek}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                            {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              fontWeight: 480,
                                              width: '150px',
                                            }}
                                          >
                                            {row?.dayOfWeek}
                                          </TableCell>
                                          {[0, 1, 2]?.map(ind => (
                                            <TableCell
                                              style={{ width: '150px' }}
                                              align="center"
                                            >
                                              {getTimeIn12HourFormat(
                                                row?.timeframes?.[ind]?.from
                                              )}{' '}
                                              -{' '}
                                              {getTimeIn12HourFormat(
                                                row?.timeframes?.[ind]?.to
                                              )}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )}

                        {key == 'caregiver-job-lead' &&
                          agency?.agency_preferences
                            ?.employeeInterviewBookingPreferences &&
                          typeof agency?.agency_preferences
                            ?.employeeInterviewBookingPreferences == 'object' &&
                          agency?.agency_preferences
                            ?.employeeInterviewBookingPreferences?.length && (
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableBody>
                                  {agency?.agency_preferences?.employeeInterviewBookingPreferences
                                    ?.filter(row => row?.timeframes?.length)
                                    ?.map(row => (
                                      <TableRow
                                        key={row?.dayOfWeek}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            fontWeight: 480,
                                            width: '150px',
                                          }}
                                        >
                                          {row?.dayOfWeek}
                                        </TableCell>
                                        {[0, 1, 2]?.map(ind => (
                                          <TableCell
                                            style={{ width: '150px' }}
                                            align="center"
                                          >
                                            {getTimeIn12HourFormat(
                                              row?.timeframes?.[ind]?.from
                                            )}{' '}
                                            -{' '}
                                            {getTimeIn12HourFormat(
                                              row?.timeframes?.[ind]?.to
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}

                        {(key === 'client-lead' &&
                          agency?.calendlyClientAppointmentUrl) ||
                          (key === 'caregiver-job-lead' &&
                            agency?.calendlyCaregiverInterviewUrl) ? (
                          <InlineWidget
                            url={
                              key === 'client-lead'
                                ? agency?.calendlyClientAppointmentUrl
                                : agency?.calendlyCaregiverInterviewUrl
                            }
                            pageSettings={{
                              backgroundColor: '#ffffff',
                              hideEventTypeDetails: true,
                              hideLandingPageDetails: true,
                              primaryColor: '#00a2ff',
                              textColor: '#4d5055',
                            }}
                            styles={{
                              height: '750px',
                              width: '100%',
                              borderRadius: '10px',
                            }}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </StyledAccordianDetails>
                </StyledAccordian>
                <Box mb={2}></Box>
              </>
            )
          } else {
            return <></>
          }
        })}
      </Box>
    </>
  )
}

export default AgencyDetailCard
