import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useTheme } from '@mui/material/styles'
import _trimStart from 'lodash/trimStart'
import { PopupModal, useCalendlyEventListener } from 'react-calendly'
import { useSnackbar } from 'notistack'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EventIcon from '@mui/icons-material/Event'
import * as Styled from './styles'
import { useSelector, dispatch } from 'src/redux/store'
import { setSelectedAgency } from 'src/slices/dashboard-slice'
import { setStoreParams } from 'src/slices/stored-params-slice'
import ActiveFormView from './active-form-view'
import LeadTypeButtonList from './lead-type-button-list'
import {
  getFormHeader,
  showCalendlyBookingButton,
  getCalendlyButtonText,
} from './helpers'
import AgencyDetailCard from './agency-detail-card'
import { createLead } from 'src/api/lead-api'
import { createCaregiver } from 'src/api/caregivers-api'
import { httpStatusCodeMapToVariant } from 'src/utils/http-status-code-map-to-variant'
import { mapToErrorResponse } from 'src/utils/map-error-response'
import ConfirmationModal from 'src/components/app-modal/confirmation'

const API_CONFIG = {
  LEAD: createLead,
  CAREGIVER: createCaregiver,
}

export function AgencyInfoView(props) {
  const { agencyInfo, selectedAgency } = useSelector(state => state.dashboard)
  const { storedParams } = useSelector(state => state.storedParams)

  const [formView, setFormView] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const confirm = useConfirm()
  const [isCalendlyOpen, setCalendlyOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false)

  const handleCalendlyClose = () => {
    setCalendlyOpen(false)
  }

  const handleConfirmationModalClose = React.useCallback(() => {
    console.log('handleConfirmationModalClose')
    setConfirmationModalOpen(false)
    window.location.reload()
  }, [])

  const handleFormView = React.useCallback(view => {
    setFormView(view)
  }, [])

  const handleSuccess = React.useCallback(
    response => {
      setFormView(null)
      setConfirmationModalOpen(true)
      enqueueSnackbar(response.message, {
        variant: httpStatusCodeMapToVariant({ statusCode: response.status }),
      })
    },
    [enqueueSnackbar]
  )

  const handleError = React.useCallback(
    ({ response, error }) => {
      mapToErrorResponse({
        response,
        error,
        status: 400,
        callback: ({ message, status }) => {
          enqueueSnackbar(message || 'Error', {
            variant: httpStatusCodeMapToVariant({ statusCode: status }),
          })
        },
      })
    },
    [enqueueSnackbar]
  )

  const handleSubmit = React.useCallback(
    payload => {
      confirm({
        title: 'Submit this Chat?',
        description: 'Are you sure you want to submit this chat?',
        confirmationText: 'Submit',
        confirmationButtonProps: { variant: 'contained' },
        dialogProps: { maxWidth: 'xs' },
      })
        .then(() => {
          setLoading(true)
          API_CONFIG[payload.type](payload.data)
            .then(response => {
              setLoading(false)
              if (response?.success) {
                handleSuccess(response)
              } else {
                handleError({ response })
              }
            })
            .catch(e => {
              /* ... */
              setLoading(false)
              handleError({ response: e?.response })
            })
        })

        .catch(() => {
          /* ... */
          setLoading(false)
        })
    },
    [confirm, handleError, handleSuccess]
  )

  React.useEffect(() => {
    if (!selectedAgency?.agency_id && agencyInfo?.agency_uuid) {
      dispatch(
        setSelectedAgency({
          agency_id: agencyInfo?.agency_uuid,
          agency_name: agencyInfo?.agency_name,
          zip_mandatory: agencyInfo?.zip_mandatory,
        })
      )
    }
  }, [
    agencyInfo?.agency_uuid,
    agencyInfo?.agency_name,
    agencyInfo?.zip_mandatory,
    selectedAgency,
  ])

  const handleUncoveredZipCode = React.useCallback(
    agencyUUID => {
      dispatch(
        setSelectedAgency({
          agency_id: agencyUUID,
          agency_name: null,
          zip_mandatory: null,
        })
      )
      dispatch(setStoreParams({ ...storedParams, i: agencyUUID }))
    },
    [storedParams]
  )

  useCalendlyEventListener({
    onDateAndTimeSelected: event => {
      console.info('onDateAndTimeSelected', event)
    },
    onEventScheduled: event => {
      console.info('onEventScheduled', event)
    },
  })

  return (
    <>
      <Styled.StyledMainViewContainer>
        <Grid container direction="row" justifyContent="center" spacing={4}>
          {formView ? (
            <Grid item xs={12} sm={4.5} md={4.5}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Box position="relative" width="100%" mb={2}>
                    <Button
                      aria-label="back"
                      edge="start"
                      startIcon={<ArrowBackIcon />}
                      sx={{ position: 'absolute', left: '-4px', top: '-4px' }}
                      onClick={() => {
                        handleFormView(null)
                      }}
                    >
                      Back
                    </Button>
                    <Typography variant="h6" color="inherit" textAlign="center">
                      {getFormHeader(formView)}
                    </Typography>
                    {showCalendlyBookingButton(formView) && (
                      <Button
                        color="primary"
                        size="small"
                        aria-label="book-appointment"
                        variant="contained"
                        startIcon={<EventIcon />}
                        sx={{
                          position: 'absolute',
                          right: '0',
                          top: '-2px',
                        }}
                        onClick={() => {
                          setCalendlyOpen(true)
                        }}
                      >
                        {getCalendlyButtonText(formView)}
                      </Button>
                    )}
                  </Box>

                  <ActiveFormView
                    formView={formView}
                    agencyInfo={agencyInfo}
                    onSubmit={handleSubmit}
                    loading={loading}
                  />
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={4.5} md={4.5}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        textAlign="left"
                        gutterBottom
                      >
                        Select Lead Type
                      </Typography>
                      <Divider variant="fullWidth" />
                    </Grid>

                    <LeadTypeButtonList
                      onClick={handleFormView}
                      leadType={
                        agencyInfo?.supported_lead_types?.supported_lead_type
                      }
                    />

                    {/* {agencyInfo?.package_type === 'PREMIUM' && (
                      <Grid item xs={12} sm={6} md={6}>
                        <Button
                          color="secondary"
                          size="large"
                          variant="outlined"
                          fullWidth
                          onClick={() => {
                            handleFormView('find-caregiver')
                          }}
                        >
                          Find Caregiver
                        </Button>
                      </Grid>
                    )} */}

                    {agencyInfo?.alternateAgencyUUID && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            textAlign="left"
                            gutterBottom
                          >
                            For uncovered zip codes.
                          </Typography>
                          <Divider variant="fullWidth" />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="secondary"
                            size="large"
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              handleUncoveredZipCode(
                                agencyInfo?.alternateAgencyUUID
                              )
                            }}
                          >
                            UNCOVERED ZIP CODES
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} sm={7.5} md={7.5}>
            <Card sx={{ height: '100%' }}>
              <CardContent style={{ padding: '0px' }}>
                <AgencyDetailCard
                  agency={agencyInfo}
                  prefrencesToShow={
                    !formView
                      ? ['client-lead', 'caregiver-job-lead']
                      : ['client-lead', 'caregiver-job-lead'].includes(formView)
                        ? [formView]
                        : []
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Styled.StyledMainViewContainer>

      <Box sx={{ height: '100px' }} />

      <PopupModal
        url="https://calendly.com/er-rajiv/schedule?hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1"
        pageSettings={{
          backgroundColor: _trimStart(theme.palette.common.black, '#'),
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: _trimStart(theme.palette.primary.main, '#'),
          textColor: _trimStart(theme.palette.common.white, '#'),
        }}
        onModalClose={handleCalendlyClose}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title="Confirmation!"
        isCancelEnabled={false}
        isConfirmEnabled={false}
        onConfirm={handleConfirmationModalClose}
      >
        <Typography variant="body1">
          Thank you for submitting this lead. Please use a new window for
          submitting a new lead.
        </Typography>
      </ConfirmationModal>
    </>
  )
}
